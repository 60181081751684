
//公共库
import { Component, Vue } from "vue-property-decorator";

//本地引入
import Pagination from "@/comp/Pagination/index.vue";
import { getUserActionReportList, exportUserActionReportList } from "@/api/request/user";
import { saveAs } from "@/api/request/base";

//组件
@Component({
  name: "UserPayStatistics",
  components: {
    Pagination,
  },
})

//函数
export default class extends Vue {
  //定义变量
  private list: any[] = [];
  private total: number = 0;
  private listLoading: boolean = false;

  //请求参数
  private listQuery: any = {
    //页面数据
    page: 1, //分页
    page_size: 10, //每页行数

    //临时数据
    user_id_str: "", //用户ID
    blogger_id_str: "", //博主ID

    //接口数据
    module: "", //模块
    user_id: 0, //用户ID
    blogger_id: 0, //博主ID
    create_time: "", //日期
  };

  //模块数据
  private moduleValue: any = {
    m_home: "主页",
    m_square: "广场",
    m_massage: "消息",
    m_mine: "我的",
  };

  //动作数据
  private actionVaue: any = {
    a_match: "单人匹配",
    a_page: "跳转页面",
    a_click_bubles: "点击泡泡",
    a_click_door: "点击开关门",
    a_duration: "逗留时间",
    a_follow: "关注",
    a_nearby: "附近",
    a_avatar: "博主头像（进入博主页面）",
    a_share: "分享",
    a_offical: "官方消息",
    a_system: "系统通知",
    a_seen: "谁看过我",
    a_meet: "这些人想认识你",
    a_market: "商场",
    a_task: "任务（每日任务的每个任务）",
    a_comment: "评论",
    a_trend: "动态",
    a_chatroom: "打开聊天室",
    a_chat: "视频/语音/聊天",
  };

  //页面数据
  private pageInfoVaue: any = {
    p_home: "主页",
    p_chat: "聊天",
    p_video: "视频",
    p_blogger_page: "进入博主个人页面",
    p_square: "广场",
    p_massage: "消息",
    p_mine: "我的",
  };

  //模块参数
  private moduleOptions: any[] = [
    { value: "", label: "全部模块" },
    { value: "m_home", label: "主页" },
    { value: "m_square", label: "广场" },
    { value: "m_massage", label: "消息" },
    { value: "m_mine", label: "我的" },
  ];

  //创建
  created() {
    //获取列表
    this.getList();
  }

  //获取列表
  private async getList() {
    //显示等待
    this.listLoading = true;

    //数据赋值
    this.listQuery.user_id = Number(this.listQuery.user_id_str);
    this.listQuery.blogger_id = Number(this.listQuery.blogger_id_str);

    //获取数据
    const { data } = await getUserActionReportList(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理查询
  private handleFilter(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取列表
    this.getList();
  }

  //处理导出
  private async handleExport() {
    //显示等待
    this.listLoading = true;

    //数据赋值
    this.listQuery.user_id = Number(this.listQuery.user_id_str);
    this.listQuery.blogger_id = Number(this.listQuery.blogger_id_str);

    //获取数据
    const { data } = await exportUserActionReportList(this.listQuery);

    //保存数据
    saveAs(data, "用户行为上报列表");

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }
}
